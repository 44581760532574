<template>
    <div>
         <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="error"
      spinner="bar-fade-scale"
    />
    <v-snackbar v-model="snackbar" color="#FEBD08">
      <v-layout wrap>
        <v-flex xs11>
          {{ msg }}
        </v-flex>
        <v-flex xs1>
          <v-icon @click="snackbar = !snackbar">mdi-close</v-icon>
        </v-flex>
      </v-layout>
    </v-snackbar>
     <v-layout wrap justify-center pa-lg-16>
        
         <v-flex xs12 md10 pa-3 text-left class="regular">
           <h1 style="border-bottom:3px solid black">{{heading}}</h1>  
         </v-flex>
         <v-flex xs12 md10 pa-3 text-left v-html="description" class="regular">
            
         </v-flex>
         
       
     </v-layout>
    </div>
</template>
<script>
import axios from "axios"
export default {
    data(){
        return{
            heading:null,
            description:null,
            appLoading:false,
            snackbar:false,
            msg:null

        }
    },
    mounted()
    {
       this.getData()
    },
    methods:
    {
        addData()
        {
            axios({
                method:"post",
                url:"/privacy/policy/addedit",
                 headers: {
                 token: localStorage.getItem("token"),
              },
                data:{
                    heading:this.heading,
                    privacypolicy:this.description
                }
            })
            .then((response) =>{
               if(response.data.status) 
               {
                 this.snackbar =true;
                 this.msg= response.data.msg;
               }
               else
               {
                 this.snackbar =true;
                 this.msg= response.data.msg;
               }

            })
        },
         getData()
        {
            axios({
                method:"get",
                url:"/vew/policy",
                 headers: {
                 token: localStorage.getItem("token"),
              },
               
            })
            .then((response) =>{
               if(response.data.status) 
               {
            if(response.data.data.policy)
                    {
                       this.description = response.data.data.policy
                    }
               if(response.data.data.heading)
               {
                 this.heading =response.data.data.heading
               }
               
               }
               else
               {
                 this.snackbar =true;
                 this.msg= response.data.msg;
               }

            })

        }
    }
}
</script>
